$(document).ready(function() {

    // globals
    var modalRefresh = false; // force browser refresh on modal close


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    if ($(".js-countdown").length) {
        var date = $(".js-countdown").data("countdown-date");

        $(".js-countdown").countdown(date, function(event) {
            $(".js-countdown-days").text(event.strftime('%D'));
            $(".js-countdown-hours").text(event.strftime('%H'));
            $(".js-countdown-minutes").text(event.strftime('%M'));
            $(".js-countdown-seconds").text(event.strftime('%S'));
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * fullscreen mode
    // *
    // *
    $(".js-fullscreen").click(function(){
        var elem = document.documentElement,
            mode = $(this).data("fullscreen-mode");

        $(".js-fullscreen").toggleClass("active");

        if (mode == 'request') {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        }else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }

    });



    // close modal
    $(".js-modal-button").click(function(){
        $(this).parents(".js-modal").fadeToggle();
        window.location.hash = '#';

        // refresh page
        if (modalRefresh == true) {
            location.reload();
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter agenda
    // *
    // *
    if ($(".js-agenda").length) {
        var mixer = mixitup('.js-agenda', {
            selectors: {
                target: '.js-agenda-item'
            },
            multifilter: {
                enable: true // enable the multifilter extension for the mixer
            }
        });
    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contacts
    // *
    // *
    var $cForm = $(".js-contacts-form"),
        $spinner = $(".js-contacts-spinner"),
        $noresult = $(".js-contacts-noresult");

    $cForm.submit(function(e) {
        e.preventDefault();

        //$spinner.addClass("active");
        $noresult.removeClass("active");

        $.ajax({
            url : "/assets/components/site/connector.php?action=searchcontact",
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){

            $spinner.removeClass("active");

            if(data.status == 'success'){
                // form was send

                // check if result is empty
                if (data.html_list == '') {
                    $noresult.addClass("active");
                }

                // set content
                $(".js-contacts-list").html(data.html_list);

            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });

    });

    // run contacts ajax request on opening window
    $(window).on('hashchange load', function () {
        var hash = window.location.hash.split('#')[1];

        if (hash == 'contacts') {
            $(".js-contacts-form").submit();
        }
    });


    // filter contacts
    $(".js-contacts-filter").click(function(){
        $(this).toggleClass("active").siblings().removeClass("active");

        if ($(this).hasClass("active")) {
            var group = $(this).data("group");
        }else {
            var group = $(".js-contacts-groups").data("groups");
        }

        $(".js-contacts-group").val(group);
        $(".js-contacts-form").submit();
    });

    // refresh activity every 30 seconds
    // if the user stays on the same site (example watching live stream)
    setInterval(function(){
        $.ajax({
            url : "/assets/components/site/connector.php?action=setactivitycontacts",
            type: 'GET',
            data: { contextKey : $("body").data("ctx") }
        }).done(function(data){
            if(data.status == 'success'){
                // do nothing
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }, 30000);




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * list
    // *
    // *
    var $cForm = $(".js-list-form"),
        $spinner = $(".js-list-spinner"),
        $noresult = $(".js-list-noresult");

    $cForm.submit(function(e) {
        e.preventDefault();

        //$spinner.addClass("active");
        $noresult.removeClass("active");

        $.ajax({
            url : "/assets/components/site/connector.php?action=searchlist",
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){

            $spinner.removeClass("active");

            if(data.status == 'success'){
                // form was send

                // check if result is empty
                if (data.html_list == '') {
                    $noresult.addClass("active");
                }

                // set content
                $(".js-list-list").html(data.html_list);

            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });

    });

    // run contacts ajax request on opening window
    $(window).on('hashchange load', function () {
        var hash = window.location.hash.split('#')[1];

        if (hash == 'list') {
            $(".js-list-form").submit();
        }
    });






    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * usernav
    // *
    // *
    $(".js-usernav-button").click(function(){
        var $button = $(this),
            $nav = $button.find(".js-usernav");

        $button.toggleClass("active");
        $nav.toggleClass("active");

        $button.mouseleave(function(){
            $button.trigger("click");
        });
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * update profile
    // *
    // *
    var $pForm = $(".js-profile-form");

    $pForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=editprofile&context=" + $("body").data("ctx"),
            type: 'POST',
            data: new FormData(this),
            processData: false,
            contentType: false
        }).done(function(data){
            if(data.status == 'success'){

                // change username in header
                $(".js-username").html(data.data.fullname);

                if (data.data.image) {
                    $(".js-userimage").attr('src', '/assets/uploads/' + data.data.image);
                }

                // show saved message
                showMegaMessage('success', 'Ihre Daten wurden gespeichert.');

                // close modal
                $(".js-modal:visible").find(".js-modal-button").first().trigger("click");

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });

    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * notifications
    // *
    // *
    if ($(".js-notification").length) {
        showNotifications();
    }

    $(".js-notification-wrapper").on("click", ".js-notification-close", function(){
        var $notify = $(this).parents(".js-notification"),
            notifyId = $notify.data("notification-id");

        $notify.removeClass("active").remove();
        localStorage.setItem("notify-" + notifyId ,1);
    });

    function showNotifications() {
        $(".js-notification").each(function(){
            var notifyId = $(this).data("notification-id"),
                status = localStorage.getItem("notify-" + notifyId);

            if (status != 1) {
                $(this).addClass("active");
            }else {
                $(this).remove();
            }
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * event Notifications
    // *
    // *
    setInterval(function(){
        checkEventNotification();
    }, 300000); // 5 minutes

    checkEventNotification();

    function checkEventNotification() {
        $.ajax({
            url : "/assets/components/site/connector.php?action=checkeventnotification",
            type: 'GET',
            data: { context : $("body").data("ctx") }
        }).done(function(data){
            if(data.status == 'success'){
                $(".js-notification-wrapper").html(data.output);

                // show notifications if not already closed
                showNotifications();
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }







    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contact Form
    // *
    // *
    var $ctForm = $(".js-contact-form");

    $ctForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=sendcontact&context=" + $("body").data("ctx"),
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                // empty fields
                $(".js-contact-form textarea").val('');

                // show saved message
                showMegaMessage('success', 'Ihre Nachricht wurde versendet.');

                // close modal
                $(".js-modal:visible").find(".js-modal-button").first().trigger("click");

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * megaMessage
    // *
    // *
    function showMegaMessage(type = '', label = '') {
        var $megaMessage = $(".js-megamessage");

        $("body").data("text", 20);

        $megaMessage.fadeIn().addClass("active")
            .attr("data-type", type)
            .find(".js-megamessage-label").html(label);

        setTimeout(function(){
            $megaMessage.fadeOut().removeClass("active")
                .attr("data-type", '')
                .find(".js-megamessage-label").html('');
        }, 2000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * resend login
    // *
    // *
    $(".js-resendlogin-button").click(function(){
        $(".js-login").hide();
        $(".js-resendlogin").fadeIn();
    });

    // resend login data
    var $resendLoginForm = $(".js-resendlogin");

    $resendLoginForm.submit(function(e) {
        e.preventDefault();

        $.ajax({
            url : "/assets/components/site/connector.php?action=resendlogin&context=" + $("body").data("ctx"),
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                $(".js-login-message").html(data.message).fadeIn();

            }else if(data.status == 'error'){
                console.log(data);
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * set User as visitor if users is vistiting the expo desk
    // *
    // *
    if ($("[data-expo]").length) {
        setInterval(function(){
            setDeskUserActivity();
        }, 10000);

        setDeskUserActivity();

        function setDeskUserActivity() {
            $.ajax({
                url : "/assets/components/site/connector.php?action=setdeskuser",
                type: 'GET',
                data: { resourceId : $("[data-expo]").data("expo"), contextKey : $("body").data("ctx") }
            }).done(function(data){
                if(data.status == 'success'){
                    // do nothing
                }else if(data.status == 'error'){
                    // handle Error
                    console.log('Error:' + data);
                }
            });
        }
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * get Desk User visitors
    // *
    // *

    // run deskuser ajax request on opening window
    $(window).on('hashchange load', function () {
        var hash = window.location.hash.split('#')[1];

        if (hash == 'deskuser') {
            getDeskUserActivity();

            setInterval(function(){
                getDeskUserActivity();
            }, 10000);
        }
    });

    function getDeskUserActivity() {
        $.ajax({
            url : "/assets/components/site/connector.php?action=getdeskuser",
            type: 'GET',
            data: { resourceId : $("[data-expo]").data("expo") }
        }).done(function(data){
            if(data.status == 'success'){
                $(".js-deskuser-list").html(data.output);
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * talk Form
    // *
    // *
    var $talkForm = $(".js-talk-form");

    $talkForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=sendtalk&context=" + $("body").data("ctx"),
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                // empty fields
                $(".js-talk-form textarea").val('');
                $(".js-talk-form .js-field-time select").val('');

                // disable time select option
                $talkForm.find(".js-field-time option[value=" + data.time + "]").attr("disabled", 'disabled');

                // show saved message
                showMegaMessage('success', 'Ihre Terminanfrage wurde versendet.');

                // close modal
                $(".js-modal:visible").find(".js-modal-button").first().trigger("click");

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * talk Confirm Form
    // *
    // *
    var $talkConfirmForm = $(".js-talkconfirm-form");

    $talkConfirmForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=confirmtalk",
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                // empty fields
                $(".js-talk-form textarea").val('');

                // show saved message
                showMegaMessage('success', 'Dieser Termin wurde bestätigt.');

                // close modal
                $(".js-modal:visible").find(".js-modal-button").first().trigger("click");

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add Talk
    // *
    // *
    var $addTalkForm = $(".js-addtalk-form");

    $addTalkForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=addtalk&context=" + $("body").data("ctx"),
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                // empty fields
                $(".js-addtalk-form .js-field-time input").val('');

                // load data
                getEditableTalks();

                // force browser refresh after modal close
                modalRefresh = true;

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });
    });

    // get all talks
    function getEditableTalks() {
        $.ajax({
            url : "/assets/components/site/connector.php?action=geteditabletalks",
            type: 'GET',
            data: { resourceId : $("[data-expo]").data("expo") }
        }).done(function(data){
            console.log(data);
            if(data.status == 'success'){
                $(".js-edittalk-list").html(data.output);
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }

    // run contacts ajax request on opening window
    $(window).on('hashchange load', function () {
        var hash = window.location.hash.split('#')[1];

        if (hash == 'edittalks') {
            getEditableTalks();
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * talk remove item
    // *
    // *
    $(".js-edittalk-list").on("click", ".js-removetalk", function(e){
        var id = $(this).data("talkid");

        $.ajax({
            url : "/assets/components/site/connector.php?action=removetalk",
            type: 'GET',
            data: { resourceId : $("[data-expo]").data("expo"), id: id, context: $("body").data("ctx") }
        }).done(function(data){
            if(data.status == 'success'){
                getEditableTalks();

                // force browser refresh after modal close
                modalRefresh = true;
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * check if talk is available
    // *
    // *
    $(".js-talk-button").click(function(){
        $.ajax({
            url : "/assets/components/site/connector.php?action=checktalklink",
            type: 'GET',
            data: { resourceId : $("[data-expo]").data("expo"), context: $("body").data("ctx") }
        }).done(function(data){
            if(data.status == 'success'){
                if (data.talk == true) {
                    window.open(data.talk_link, '_blank');
                }else {
                    window.location.hash = '#talk';
                }
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggleSetting
    // *
    // *
    $(".js-togglesetting").on("change", function(){
        var status,
            tv = $(this).data("name");

        if ($(this).is(':checked')) { status = 1; }else { status = 0; }

        $.ajax({
            url : "/assets/components/site/connector.php?action=updatetv",
            type: 'GET',
            data: { resourceId : $("[data-expo]").data("expo"), tv: tv, value: status, context: $("body").data("ctx") }
        }).done(function(data){
            if(data.status == 'success'){
                // force browser refresh after modal close
                modalRefresh = true;
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });

    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * edit Video Form
    // *
    // *
    var $editVideoForm = $(".js-editvideo-form");

    $editVideoForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=updatevideo&context=" + $("body").data("ctx"),
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                // change video url
                $(".js-video-link").attr('href', data.video);

                // show saved message
                showMegaMessage('success', 'Die Video-URL wurde gespeichert.');

                // close modal
                $(".js-modal:visible").find(".js-modal-button").first().trigger("click");

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * edit Settings Form
    // *
    // *
    var $editSettingsorm = $(".js-editsettings-form");

    $editSettingsorm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=updatesettings&context=" + $("body").data("ctx"),
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                // show saved message
                showMegaMessage('success', 'Ihre Einstellungen wurden gespeichert.');

                // close modal
                $(".js-modal:visible").find(".js-modal-button").first().trigger("click");

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });
    });






    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add Downloads
    // *
    // *
    var $addDownloadsForm = $(".js-adddownloads-form");

    $addDownloadsForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=adddownloads&context=" + $("body").data("ctx"),
            type: 'POST',
            data: new FormData(this),
            processData: false,
            contentType: false
        }).done(function(data){
            if(data.status == 'success'){

                // empty fields
                $(".js-adddownloads-form .js-field input, .js-adddownloads-form .js-field textarea").val('');

                // force browser refresh after modal close
                modalRefresh = true;

                // load data
                getEditableDownloads();

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });
    });

    // get all downloads
    function getEditableDownloads() {
        $.ajax({
            url : "/assets/components/site/connector.php?action=geteditabledownloads",
            type: 'GET',
            data: { resourceId : $("[data-expo]").data("expo") }
        }).done(function(data){
            if(data.status == 'success'){
                $(".js-editdownloads-list").html(data.output);
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }

    // run ajax request on opening window
    $(window).on('hashchange load', function () {
        var hash = window.location.hash.split('#')[1];

        if (hash == 'editdownloads') {
            getEditableDownloads();
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * download remove item
    // *
    // *
    $(".js-editdownloads-list").on("click", ".js-removedownload", function(e){
        var id = $(this).data("downloadid");

        $.ajax({
            url : "/assets/components/site/connector.php?action=removedownload",
            type: 'GET',
            data: { resourceId : $("[data-expo]").data("expo"), id: id, context: $("body").data("ctx") }
        }).done(function(data){
            if(data.status == 'success'){
                getEditableDownloads();

                // force browser refresh after modal close
                modalRefresh = true;
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * logo Upload
    // *
    // *
    var $editLogoForm = $(".js-editlogo-form");

    $editLogoForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=updatelogo&context=" + $("body").data("ctx"),
            type: 'POST',
            data: new FormData(this),
            processData: false,
            contentType: false
        }).done(function(data){
            if(data.status == 'success'){

                // change logo url
                $(".js-logo").attr("src", data.logo);

                // show saved message
                showMegaMessage('success', 'Ihr Logo wurde gespeichert.');

                // close modal
                $(".js-modal:visible").find(".js-modal-button").first().trigger("click");

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * wallImage Upload
    // *
    // *
    var $editWallImageForm = $(".js-editwallimage-form");

    $editWallImageForm.submit(function(e) {
        e.preventDefault();

        // remove all errors
        $(".js-field").removeClass("form__field--error");
        $(".js-field-error").html('');

        $.ajax({
            url : "/assets/components/site/connector.php?action=updatewallimage&context=" + $("body").data("ctx"),
            type: 'POST',
            data: new FormData(this),
            processData: false,
            contentType: false
        }).done(function(data){
            if(data.status == 'success'){

                // change logo url
                $(".js-wallimage").attr("src", data.wallimage);

                // show saved message
                showMegaMessage('success', 'Ihre Grafik wurde gespeichert.');

                // close modal
                $(".js-modal:visible").find(".js-modal-button").first().trigger("click");

            }else if(data.status == 'error'){
                // show errors
                $.each(data.errors, function(index, value){
                    $(".js-field-" + index).addClass("form__field--error").find(".js-field-error").html(value);
                });

            }
        });
    });






    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Chat globals
    // *
    // *

    // Auto scroll chat window
    function autoScrollGroupChat() {
        var scrollHeight = $(".js-group-chat-scroll").prop('scrollHeight'); //Scroll height after the request
    	$(".js-group-chat-scroll").scrollTop(scrollHeight);
    }

    // Auto scroll chat window
    function autoScrollSingleChat() {
        var scrollHeight = $(".js-chat-scroll").prop('scrollHeight'); //Scroll height after the request
    	$(".js-chat-scroll").scrollTop(scrollHeight);
    }








    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Group-Chat
    // *
    // *

    // open aside chat
    $(".js-chat-aside-button").click(function(){
        $(".js-chat-aside, .js-chat-aside-background").toggleClass("active");
        $(".js-group-chat-timeline").html('');

        // toggle actions (open/close)
        if ($(".js-chat-aside").hasClass("active")) {

            // get latest messages
            getGroupChatMessages();

            // set conversation value
            $(".js-group-chat-conversation").val($("body").data("cc-group"));

            // start interval
            startGroupMessageCheckInterval();

        } else {
            stopGroupMessageCheckInterval();
        }
    });

    // start messageCheck interval
    function startGroupMessageCheckInterval() {
        checkNewGroupMessagesInterval = setInterval(function(){
            var lastMessageId = $(".js-group-chat-timeline [data-message-id]:last-child").data("message-id");

            checkNewGroupMessages(lastMessageId);

        }, 3000);
    }

    // stop messageCheck interval
    function stopGroupMessageCheckInterval() {
        clearInterval(checkNewGroupMessagesInterval);
    }


    // send group message
    $(".js-chat-form-group").submit(function(e){
        e.preventDefault();

        // check if message is empty
        if ($(".js-chat-form-message").val() == '') return;

        // disable button to prevent sendig twice
        $(".js-chat-form-group").find("button").attr('disabled','disabled');

        // stop message checking to avoid duplicate messages in timeline
        stopGroupMessageCheckInterval();

        // make request
        $.ajax({
            url : "/assets/components/site/connector.php?action=sendmessage&context=" + $("body").data("ctx"),
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                // clean up message form
                $(".js-chat-form-message").val('');

                // show message
                var lastMessageId = $(".js-group-chat-timeline [data-message-id]:last-child").data("message-id");
                getGroupChatMessages(lastMessageId);

                // start message checking again
                startGroupMessageCheckInterval();

                // enable button to send new messages
                $(".js-chat-form-group").find("button").attr('disabled', false);

            } else if(data.status == 'error'){
                console.log(data);
            }
        });
    });

    // check for new messages
    function checkNewGroupMessages(messageId) {
        $.ajax({
            url : "/assets/components/site/connector.php?action=checkmessages",
            type: 'GET',
            data: { conversation : $("body").data("cc-group"), message_id : messageId }
        }).done(function(data){
            if(data.status == 'success'){

                // if new messages available load them
                if (data.new) {
                    getGroupChatMessages(messageId);
                }

            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }


    // get chat messages
    function getGroupChatMessages(messageId = false) {
        $.ajax({
            url : "/assets/components/site/connector.php?action=getmessages",
            type: 'GET',
            data: { conversation : $("body").data("cc-group"), message_id : messageId, context : $("body").data("ctx"), time : "- 1 day" }
        }).done(function(data){
            if(data.status == 'success'){
                //console.log(data.data);

                // hande chat messages
                $.each(data.data, function(index, message) {
                    handleGroupChatMessage(message);
                });

            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }


    // handle chat message
    function handleGroupChatMessage(message) {

        // data
        var selfStyle = (message.self == true) ? 'chat__messageBlock--self' : '',
            htmlMessage = '<div class="chat__messageBlock ' + selfStyle + '" data-message-id="' + message.id + '"><div class="chat__imageWrapper"><img class="chat__image" title="' + message.id + '" src="' + message.sender.image + '" alt=""></div><div class="chat__content"><div class="chat__time">' + message.sender.fullname + ', ' + message.date_formatted + '</div><ul class="chat__messages"><li class="chat__message"><div class="chat__messageInner">' + message.message + '</div></li></ul></div></div>';

        $(".js-group-chat-timeline").append(htmlMessage);

        autoScrollGroupChat();
    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Single-Chat
    // *
    // *

    // start chat window
    $(window).on('hashchange load', function () {
        var hash = window.location.hash.split('#')[1];

        if (hash == 'chats') {
            loadConversationList();
        }
    });

    // load Conversations
    function loadConversationList(activeConversation = false) {

        // empty userList
        $(".js-chat-userlist").html('');

        // empty timeline
        $(".js-chat-timeline").html('');

        // get conversations
        $.ajax({
            url : "/assets/components/site/connector.php?action=getconversationlist",
            type: 'GET',
            data: { context : $("body").data("ctx") }
        }).done(function(data){
            if(data.status == 'success'){
                //console.log(data.data);

                // hande chat messages
                $.each(data.data, function(index, conversation) {
                    // templating conversation
                    var unread = (conversation.unread >= 1) ? 'unread' : '',
                        count = (conversation.unread >= 1) ? conversation.unread : '',
                        conversation = '<li class="chat__userListItem js-chat-userlist-item '+ unread +'" data-conversation="'+ conversation.conversation +'" data-userid="'+ conversation.sender.id +'"><div class="contacts__imageWrapper"><span class="chat__userListNotify js-chat-userlist-notify">'+ count +'</span><img class="chat__userListImage" src="'+ conversation.sender.image +'"></div><div><div class="chat__userListName">'+ conversation.sender.fullname +'</div><div class="chat__userListInfo">' + conversation.date_formatted + '</div></div></li>';
                    $(".js-chat-userlist").append(conversation);
                });

            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }

    // load chat history
    $(document).on("click", ".js-chat-userlist-item", function(){
        var conversationId = $(this).data("conversation"),
            userId = $(this).data("userid");

        // empty chat history
        $(".js-chat-timeline").html('');

        // get Chat messages
        getSingleChatMessages(conversationId);

        // set receiver id for form and empty message field
        $(".js-chat-conversation").val(conversationId);
        $(".js-chat-receiver").val(userId);
        $(".js-chat-message").val('');

        // mark conversation as active
        $(this).removeClass("unread").addClass("active").siblings().removeClass("active");
        $(this).find(".js-chat-userlist-notify").html('');

        // start to check for new messages
        startMessageCheckInterval();
    });


    // start messageCheck interval
    function startMessageCheckInterval() {

        // stop already running intervals
        if (typeof checkNewMessagesInterval !== 'undefined') {
            stopMessageCheckInterval();
        }

        checkNewMessagesInterval = setInterval(function(){
            checkNewMessages();
        }, 3000);
    }

    // stop messageCheck interval
    function stopMessageCheckInterval() {
        clearInterval(checkNewMessagesInterval);
    }


    // check new messages
    function checkNewMessages() {
        var messageId = $(".js-chat-timeline [data-message-id]:last-child").data("message-id"),
            conversationId = $(".js-chat-userlist-item.active").data("conversation");

        $.ajax({
            url : "/assets/components/site/connector.php?action=checkmessages",
            type: 'GET',
            data: { conversation : conversationId, message_id : messageId }
        }).done(function(data){
            if(data.status == 'success'){
                //console.log(data);

                // if new messages available load them
                if (data.new) {
                    getSingleChatMessages(conversationId, messageId);
                }

            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }


    // get chat messages
    function getSingleChatMessages(conversationId, messageId = false) {
        $.ajax({
            url : "/assets/components/site/connector.php?action=getmessages",
            type: 'GET',
            data: { conversation : conversationId, message_id : messageId, context : $("body").data("ctx") }
        }).done(function(data){
            if(data.status == 'success'){
                //console.log(data.data);

                // hande chat messages
                $.each(data.data, function(index, message) {
                    handleSingleChatMessage(message);
                });

                // change notifications
                checkNotifyMessages();

            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }


    // handle chat message
    function handleSingleChatMessage(message) {

        // data
        var selfStyle = (message.self == true) ? 'chat__messageBlock--self' : '',
            htmlMessage = '<div class="chat__messageBlock ' + selfStyle + '" data-message-id="' + message.id + '"><div class="chat__imageWrapper"><img class="chat__image" title="' + message.id + '" src="' + message.sender.image + '" alt=""></div><div class="chat__content"><div class="chat__time">' + message.sender.fullname + ', ' + message.date_formatted + '</div><ul class="chat__messages"><li class="chat__message"><div class="chat__messageInner">' + message.message + '</div></li></ul></div></div>';

        $(".js-chat-timeline").append(htmlMessage);

        autoScrollSingleChat();
    }

    // mark messages as read
    // function markMessagesRead(conversationId) {
    //     $.ajax({
    //         url : "/assets/components/site/connector.php?action=markmessagesread",
    //         type: 'GET',
    //         data: { conversation : conversationId, context : $("body").data("ctx") }
    //     }).done(function(data){
    //         if(data.status == 'success'){
    //
    //         }else if(data.status == 'error'){
    //             // handle Error
    //             console.log('Error:' + data);
    //         }
    //     });
    // }


    // send chat message
    $(".js-chat-form-single").submit(function(e){
        e.preventDefault();

        // check if message is empty
        if ($(".js-chat-message").val() == '') return;

        // disable button to prevent sendig twice
        $(this).find("button").attr('disabled','disabled');

        // data
        var conversationId = $(".js-chat-conversation").val();

        // make request
        $.ajax({
            url : "/assets/components/site/connector.php?action=sendmessage&context=" + $("body").data("ctx"),
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                // clean up message form
                $(".js-chat-message").val('');

                // show message
                var lastMessageId = $(".js-chat-timeline [data-message-id]:last-child").data("message-id");
                getSingleChatMessages(conversationId, lastMessageId);

                // enable button to send new messages
                $(".js-chat-form-single").find("button").attr('disabled', false);

            } else if(data.status == 'error'){
                console.log(data);
            }
        });
    });


    // stop all chat checks
    $(window).on('hashchange', function () {
        var hash = window.location.hash.split('#')[1];

        if (!hash) {
            // stop new message chat checks
            if (typeof checkNewMessagesInterval !== 'undefined') {
                stopMessageCheckInterval();
            }
        }
    });

    // start new conversation
    $(document).on("click", ".js-start-chat", function(){
        var userId = $(this).data("userid"),
            userName = $(this).data("username");

        // set params
        $(".js-newchat-username").html(userName);
        $(".js-chat-new-receiver").val(userId);

        // open new chat message
        window.location.hash = 'new-chat';
    });

    // send new chat message
    $(".js-chat-new-form").submit(function(e){
        e.preventDefault();

        // check if message is empty
        if ($(".js-chat-new-message").val() == '') return;

        // disable button to prevent sendig twice
        $(this).find("button").attr('disabled','disabled');

        // make request
        $.ajax({
            url : "/assets/components/site/connector.php?action=sendmessage&context=" + $("body").data("ctx"),
            type: 'POST',
            data: $(this).serialize()
        }).done(function(data){
            if(data.status == 'success'){

                // clean up message form
                $(".js-chat-new-message").val('');

                // enable button to send new messages
                $(".js-chat-new-form").find("button").attr('disabled', false);

                // open chat messages
                window.location.hash = 'chats';

            } else if(data.status == 'error'){
                console.log(data);
            }
        });
    });


    // set interval to check notification
    checkNotifyInterval = setInterval(function(){
        checkNotifyMessages();
    }, 10000);

    // check nofitications
    function checkNotifyMessages() {
        $.ajax({
            url : "/assets/components/site/connector.php?action=checknotifymessages",
            type: 'GET',
            data: { context : $("body").data("ctx") }
        }).done(function(data){
            if(data.status == 'success'){
                //console.log(data);

                var count = (data.count == 0) ? '' : data.count;

                // set notify dot
                $(".js-chat-notify").html(count);

                // add cound notify to conversations
                $.each(data.data, function(index, value) {
                    var count = (value == 0) ? '' : value;
                    $('.js-chat-userlist-item[data-conversation="' + index + '"]').find(".js-chat-userlist-notify").html(count);
                });

            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }

    checkNotifyMessages();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * ie11 Fix SVG + opbject fit
    // *
    // *
    svg4everybody();
    objectFitImages();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Agenda log
    // *
    // *
    var room = $("[data-room]").data("room");

    if (room) {
        setAgendaLog();

        setInterval(function(){
            setAgendaLog();
        }, 900000); // 900000 = 15min
    }

    // set Agenda Log
    function setAgendaLog() {
        $.ajax({
            url : "/assets/components/site/connector.php?action=setagendalog",
            type: 'GET',
            data: { context : $("body").data("ctx"), room : room }
        }).done(function(data){
            if(data.status == 'success'){
                console.log(data);
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }




});





// * * * * * * * * * * * * * * * * * * * * * * * * *
// * modal window
// *
// *
$(window).on('hashchange load', function () {
    var hash = window.location.hash.split('#')[1],
        $modal = $('[data-modal="' + hash + '"]');

    if ($modal.length) {
        $(".js-modal").fadeOut();
        $modal.fadeToggle();
    }
});
